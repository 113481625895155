import { captureException } from "@sentry/react";
import { isSutroError, logError } from "sutro-common";

import { isStudioError } from "~/lib/studio-error";

import {
  STUDIO_HTTP_ERROR_TYPES,
  StudioHttpError,
} from "../sutro-api/StudioHttpError";

const isAbortedRequest = (error: Error) =>
  error instanceof StudioHttpError &&
  error.context?.type === STUDIO_HTTP_ERROR_TYPES.AbortedRequest;

/**
 * This function is designed to report an error event that hasn't been handled.
 *
 * It's optimized to display SutroErrors (and its subclasses) such that the context and cause are also shown
 *
 */
export const reportErrorEvent = (event: ErrorEvent | PromiseRejectionEvent) => {
  const error =
    event instanceof PromiseRejectionEvent ? event.reason : event.error;

  if (isAbortedRequest(error)) {
    return;
  }
  captureException(error);
  if (isSutroError(error) || isStudioError(error)) {
    logError(error, console);
    event.preventDefault();
  } else {
    console.log({
      errorName: error.name,
      errorPrototype: error.prototype,
    });
  }
};
